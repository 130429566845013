import React, { useState, useRef } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import Image from "../UI/Image/Image"
import useImage from "../../hooks/useImage"
import StatisticsTabs from "./StatisticsTabs/StatisticsTabs"
import StatisticsBackground from "./StatisticsBackground/StatisticsBackground"
import RichTextModel from "../RichTextModel/RichTextModel"
import Typography from "../UI/Typography/Typography"
import NumberCounter from "../UI/NumberCounter/NumberCounter"
import { convertToId } from "../../helpers/utils"
import AccordionItem from "../Accordion/AccordionItem"
import { defaultStyles as styles } from "./Statistics-tw-styles"
import useIsScrolledToSection from "../../hooks/useIsScrolledToSection"

const Statistics = props => {
    const {
        stickyMenuItem,
        mainTitle,
        descriptionPercentageStart,
        descriptionPercentageEnd,
        descriptionTitle,
        descriptionText,
        descriptionImg,
        tonsStart,
        tonsEnd,
        tonsText,
        farmersStart,
        farmersEnd,
        farmersText,
        hectaresStart,
        hectaresEnd,
        hectaresText,
        biodiversityStart,
        biodiversityEnd,
        biodiversityText,
        bees,
        beesText,
        andText,
        butterflies,
        butterfliesText,
        fallowsText,
        tabsCollection,
        tabsImg,
        disclaimer,
        accordion,
    } = props

    const menuId = stickyMenuItem && convertToId(stickyMenuItem?.title, true)
    const [openedIndex, setOpenedIndex] = useState(-1)
    const statisticWrapper = useRef(null)
    const { foregroundImg } = useImage(descriptionImg)
    useIsScrolledToSection(statisticWrapper, "anim-on")

    return (
        <section ref={statisticWrapper} id={menuId} className={styles?.wrapper}>
            <div className={styles?.container}>
                <h2 className={styles?.title}>{mainTitle}</h2>

                <div className={styles?.descRow}>
                    <div className={styles?.descLeftSide}>
                        <div className={clsx(styles?.descPercentage, "hidden mlg:flex mlg:mt-[-9px]")}>
                            <NumberCounter
                                start={descriptionPercentageStart}
                                end={descriptionPercentageEnd}
                                prefix="~"
                            />
                            %
                        </div>

                        <div className={styles?.descTextBlock}>
                            <div className={styles?.descTitle}>
                                <div className={clsx(styles?.descPercentage, "mlg:hidden")}>
                                    <NumberCounter
                                        start={descriptionPercentageStart}
                                        end={descriptionPercentageEnd}
                                        prefix="~"
                                    />
                                    %
                                </div>
                                {descriptionTitle}
                            </div>
                            <div className={styles?.descText}>{descriptionText}</div>
                        </div>
                    </div>

                    <Image contentfulImage={foregroundImg} className={styles?.descImage} />
                </div>

                <div className={styles?.greenWrapper}>
                    <Image contentfulImage={foregroundImg} className={styles?.greenImage} />

                    <ul className={styles?.greenList}>
                        <li>
                            <NumberCounter className={styles?.greenTitle} start={tonsStart} end={tonsEnd} prefix="~" />
                            <Typography className={styles?.greenText} content={tonsText} />
                        </li>
                        <li>
                            <NumberCounter className={styles?.greenTitle} start={farmersStart} end={farmersEnd} />
                            <Typography className={styles?.greenText} content={farmersText} />
                        </li>
                        <li>
                            <NumberCounter
                                className={styles?.greenTitle}
                                start={hectaresStart}
                                end={hectaresEnd}
                                prefix="~"
                            />
                            <Typography className={styles?.greenText} content={hectaresText} />
                        </li>
                        <li>
                            <NumberCounter
                                className={styles?.greenTitle}
                                start={biodiversityStart}
                                end={biodiversityEnd}
                                prefix="~"
                            />
                            <Typography className={styles?.greenText} content={biodiversityText} />
                        </li>
                    </ul>
                </div>

                <div className={styles?.butterfliesWrapper}>
                    <div className={styles?.butterflies} />
                </div>

                <div className={styles?.orangeWrapper}>
                    <div className={styles?.orangeContainer}>
                        <div className={styles?.orangeCol}>
                            <div className={clsx(styles?.orangeNumber, "bg-[#DD7C00]")}>{bees}</div>
                            <div className={styles?.orangeLabel}>{beesText}</div>
                        </div>
                        <div
                            className={clsx(styles?.orangeText, "statistic-and-text md:w-full mlg:w-auto !text-center")}
                        >
                            {andText}
                        </div>
                        <div className={styles?.orangeCol}>
                            <div className={clsx(styles?.orangeNumber, "bg-[#7F9F13]")}>{butterflies}</div>
                            <div className={styles?.orangeLabel}>{butterfliesText}</div>
                        </div>
                    </div>
                    <div
                        className={clsx(styles?.orangeText, "w-full md:w-auto mt-[8px] md:mt-0 md:ml-[40px] mlg:ml-0")}
                    >
                        {fallowsText}
                    </div>
                </div>

                <StatisticsTabs tabs={tabsCollection.items} tabsImage={tabsImg} />

                <div className={styles?.disclaimer}>
                    <RichTextModel richText={disclaimer} />
                    {accordion && (
                        <AccordionItem
                            isOpen={openedIndex === 0}
                            name={accordion?.name}
                            content={accordion?.readMoreContent}
                            contentLong={accordion?.readMoreContentLong}
                            desktopContent={accordion?.desktopContent}
                            setOpenedIndex={setOpenedIndex}
                            index={0}
                            variant={accordion?.variant}
                        />
                    )}
                </div>
            </div>

            <div className={styles?.bgWrapper}>
                <StatisticsBackground />
            </div>
        </section>
    )
}

Statistics.propTypes = {
    stickyMenuItem: PropTypes.object,
    mainTitle: PropTypes.string,
    descriptionPercentageStart: PropTypes.number,
    descriptionPercentageEnd: PropTypes.number,
    descriptionTitle: PropTypes.string,
    descriptionText: PropTypes.string,
    descriptionImg: PropTypes.object,
    tonsStart: PropTypes.number,
    tonsEnd: PropTypes.number,
    tonsText: PropTypes.string,
    farmersStart: PropTypes.number,
    farmersEnd: PropTypes.number,
    farmersText: PropTypes.string,
    hectaresStart: PropTypes.number,
    hectaresEnd: PropTypes.number,
    hectaresText: PropTypes.string,
    biodiversityStart: PropTypes.number,
    biodiversityEnd: PropTypes.number,
    biodiversityText: PropTypes.string,
    bees: PropTypes.string,
    beesText: PropTypes.string,
    andText: PropTypes.string,
    butterflies: PropTypes.string,
    butterfliesText: PropTypes.string,
    fallowsText: PropTypes.string,
    tabsCollection: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                iconLabel: PropTypes.string,
                icon: PropTypes.object,
                columns: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string,
                        content: PropTypes.object,
                    })
                ),
            })
        ),
    }),
    tabsImg: PropTypes.object,
    disclaimer: PropTypes.object,
    accordion: PropTypes.object,
}

export default Statistics
