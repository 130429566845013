const query = isPreview => `{
  headerCollection(preview: ${isPreview}, limit: 15) {
    items {
    languageCode
      logo {
        url
        title
        description
        width
        height
      }
      logoScrolled {
        url
        title
        description
        width
        height
      }
    }
  }
}
`

module.exports = { query }
