import * as React from "react"

const ExpandIcon = className => (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.466998 0.472355C0.758409 0.177986 1.23328 0.175588 1.52764 0.466998L6.52765 5.41675C6.82201 5.70816 6.82441 6.18303 6.533 6.4774C6.24159 6.77176 5.76672 6.77416 5.47235 6.48275L0.472355 1.533C0.177986 1.24159 0.175588 0.766724 0.466998 0.472355Z"
            fill="#0B612D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.533 13.4724C6.82441 13.7667 6.82201 14.2416 6.52765 14.533L1.52765 19.4828C1.23328 19.7742 0.758409 19.7718 0.466998 19.4774C0.175588 19.183 0.177986 18.7082 0.472355 18.4167L5.47235 13.467C5.76672 13.1756 6.24159 13.178 6.533 13.4724Z"
            fill="#0B612D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5205 13.4697C13.8133 13.1768 14.2882 13.1768 14.5811 13.4697L19.5309 18.4194C19.8238 18.7123 19.8238 19.1872 19.5309 19.4801C19.238 19.773 18.7631 19.773 18.4702 19.4801L13.5205 14.5303C13.2276 14.2374 13.2276 13.7626 13.5205 13.4697Z"
            fill="#0B612D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.4801 0.46967C19.773 0.762563 19.773 1.23744 19.4801 1.53033L14.5303 6.48008C14.2374 6.77297 13.7626 6.77297 13.4697 6.48008C13.1768 6.18719 13.1768 5.71231 13.4697 5.41942L18.4194 0.46967C18.7123 0.176777 19.1872 0.176777 19.4801 0.46967Z"
            fill="#0B612D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.75 1C13.75 0.585786 14.0858 0.25 14.5 0.25H19C19.4142 0.25 19.75 0.585786 19.75 1V5.5C19.75 5.91421 19.4142 6.25 19 6.25C18.5858 6.25 18.25 5.91421 18.25 5.5V1.75H14.5C14.0858 1.75 13.75 1.41421 13.75 1Z"
            fill="#0B612D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 13.75C19.4142 13.75 19.75 14.0858 19.75 14.5V19C19.75 19.4142 19.4142 19.75 19 19.75H14.5C14.0858 19.75 13.75 19.4142 13.75 19C13.75 18.5858 14.0858 18.25 14.5 18.25H18.25V14.5C18.25 14.0858 18.5858 13.75 19 13.75Z"
            fill="#0B612D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 13.75C1.41421 13.75 1.75 14.0858 1.75 14.5V18.25H5.5C5.91421 18.25 6.25 18.5858 6.25 19C6.25 19.4142 5.91421 19.75 5.5 19.75H1C0.585786 19.75 0.25 19.4142 0.25 19V14.5C0.25 14.0858 0.585786 13.75 1 13.75Z"
            fill="#0B612D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H5.5C5.91421 0.25 6.25 0.585786 6.25 1C6.25 1.41421 5.91421 1.75 5.5 1.75H1.75V5.5C1.75 5.91421 1.41421 6.25 1 6.25C0.585786 6.25 0.25 5.91421 0.25 5.5V1Z"
            fill="#0B612D"
        />
    </svg>
)

export default ExpandIcon
