import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import Image from "../../Image/Image"
import Typography from "../../Typography/Typography"
import useImage from "../../../../hooks/useImage"
import * as styles from "../ImageCard-tw-styles"
import ArrowLeftUp from "../../../../assets/icons/ArrowLeftUp"
import useScreenSize from "../../../../hooks/useScreenSize"
import { animIn, animOut } from "../../../../helpers/anim.helpers"
import clsx from "clsx"
import { useGlobalContext } from "../../../../context/GlobalContext"
import Icon from "../../Icon/Icon"
import ModalWrapper from "../../../Wrappers/ModalWrapper/ModalWrapper"

const HeroCard = props => {
    const { text, title, images, image, variant, gaEventClass, gaEventLabel } = props
    const { foregroundImg, backgroundImg } = useImage(images)
    const style = styles[variant] || styles.defaultStyle
    const [isOpen, setIsOpen] = useState(false)
    const [isExpanded, setIsExpanded] = useState(false)
    const [height, setHeight] = useState("0")
    const windowSize = useScreenSize()
    const expandedContent = useRef(null)
    const globalContext = useGlobalContext()

    let localisedDescriptionClass

    const openModal = (e) => {
        e.stopPropagation()
        setIsExpanded(true)
    }

    if (
        globalContext.locale === "de-de" ||
        globalContext.locale === "fr-fr" ||
        globalContext.locale === "it-it" ||
        globalContext.locale === "ro-ro" ||
        globalContext.locale === "nl-be" ||
        globalContext.locale === "fr-be" ||
        globalContext.locale === "nl-nl"
    ) {
        localisedDescriptionClass = "mlg:[&>p]:text-[14px] xlg:[&>p]:text-[16px]"
    }

    useEffect(() => {
        if (isOpen) {
            setHeight("auto")
            animIn(expandedContent.current).play()
        } else {
            animOut(expandedContent.current).play()
            const timer = setTimeout(() => {
                setHeight("0")
            }, 300)

            return () => clearTimeout(timer)
        }
    }, [isOpen, windowSize])

    return (
        <>
            <div className={style?.imageBoxWraper} onClick={() => setIsOpen(!isOpen)}>
                <div className={style?.imageBoxWrpr}>
                    <div className={style?.imgContainer}>
                        {backgroundImg && (
                            <button type="button" className={style?.expandIconContainer} onClick={openModal}>
                                <Icon name="ExpandIcon" />
                            </button>
                        )}
                        <Image
                            contentfulImage={image || foregroundImg}
                            className={style?.imgWrpr}
                            eventClass={gaEventClass}
                            eventLabel={gaEventLabel}
                        />
                    </div>
                    <div className={style?.contentWrapr}>
                        {title && (
                            <Typography
                                className={clsx(style?.heading, isOpen ? "mb-[16px]" : "mb-[8px]")}
                                content={title}
                            />
                        )}
                        {text && (
                            <>
                                <div className="overflow-hidden" style={{ height }}>
                                    <div ref={expandedContent}>
                                        <Typography
                                            className={clsx(style?.description, localisedDescriptionClass)}
                                            content={text}
                                        />
                                    </div>
                                </div>
                                <div className="cards-icon / flex justify-end">
                                    <ArrowLeftUp />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {isExpanded && (
                <ModalWrapper
                    variant="HERO_CARD_MODAL"
                    closeModal={() => setIsExpanded(false)}
                    isModalOpen={isExpanded}
                    closeOnBodyclick={true}
                >
                    <div className={style?.expandedImageContainer}>
                        <Image className={style?.expandedImage} contentfulImage={backgroundImg} />
                    </div>
                </ModalWrapper>
            )}
        </>
    )
}

HeroCard.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.object,
    image: PropTypes.object,
    images: PropTypes.object,
    variant: PropTypes.string,
    gaEventClass: PropTypes.string,
    gaEventLabel: PropTypes.string,
}

export default HeroCard
