export const defaultStyle = {
    wrapper: "relative h-[400px] md:h-[320px] mlg:h-[372px] mlg:max-[1280px]:h-[291px] xlg:h-[476px] mlg:px-[8px]",

    front: "flip-card-front absolute h-[400px] md:h-[320px] mlg:h-[372px] mlg:max-[1280px]:h-[291px] xlg:h-[476px]",
    frontContainer: "relative",
    frontImage: "",
    quoteIcon: "absolute top-[32px] left-[32px]",
    arrowIcon: "cards-icon flex justify-end absolute right-[32px] bottom-[32px] rounded-full p-[8px] bg-white",

    back: "flip-card-back absolute h-[400px] md:h-[320px] mlg:h-[372px] mlg:max-[1280px]:h-[291px] xlg:h-[476px] flex flex-col justify-center px-[36px]",
    description: "body-l mlg:max-[1280px]:text-[14px] lg:text-[16px] xlg:text-[20px]",
    name: "mt-[24px] accent-body-m",
}

export const FLIP_TEXT_CARD = {
    wrapper: "relative h-[348px] max-md:h-[300px] max-slg:h-[272px]",
    front:
        "flip-text-card--box-shadow / absolute h-[348px] max-md:h-[300px] max-slg:h-[272px] rounded-[24px] overflow-hidden " +
        "before:block before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 " +
        "before:bg-hero-card-texture before:bg-repeat-y before:-z-[1] before:bg-[-100px]",
    frontContainer: "relative flex flex-col justify-center h-full px-[24px]",
    back:
        "flip-text-card--box-shadow /  bg-white absolute h-[348px] flex flex-col p-[24px] max-md:h-[300px] max-slg:h-[272px] " +
        "max-md:p-[20px] rounded-[24px] overflow-hidden ",
    description: "body-m !text-[14px] md:max-slg:!text-[15px] mlg:max-[1280px]:!text-[12px] min-[1280px]:!text-[15px]",
    frontPictogram: "flex justify-center items-center [&_+_div]:slg:mt-[24px]",
    frontPictogramImage: "w-[96px] h-[96px] slg:w-[120px] slg:h-[120px]",
    name: "style-h3 flex justify-center items-center text-center",
    arrowIcon: "cards-icon flex justify-end absolute right-[24px] bottom-[24px]",
}

