import React, {useEffect} from "react"
import clsx from 'clsx'
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import useScrollBlock from "../../../hooks/useScrollBlock"
import {gaEventClasses} from '../../../constants/gtm.constants'
import Icon from '../../UI/Icon/Icon'
import * as styles from "./ModalWrapper-tw-styles"
import FocusTrap from "focus-trap-react";

const ModalWrapper = props => {
    const {closeModal, children, variant, closeOnBodyclick} = props
    const style = styles[variant] || styles.defaultStyle
    const [blockScroll, allowScroll] = useScrollBlock()

    const handleCloseOnKeyPress = event => {
        if (event.key === "Escape" || event.key === "Esc") {
            close()
        }
    }

    const onClick = e => {
        if (closeOnBodyclick && e.currentTarget === e.target) {
            close()
        }
    }

    const close = () => {
        closeModal()
        allowScroll()
    }

    useEffect(() => {
        document.addEventListener("keydown", handleCloseOnKeyPress)

        blockScroll()

        return () => {
            document.removeEventListener("keydown", handleCloseOnKeyPress)
        }
    }, [])

    const modal = (
        <FocusTrap>
            <div className={style?.wrapper} onClick={onClick}>
                <div className={style?.gap}>
                    <div className={style?.container}>
                        <div className={style?.content}>
                            {children}

                            <button
                                className={clsx(style?.closeButton, gaEventClasses.button_click)}
                                onClick={close}
                                aria-label="Close modal"
                                data-action-detail="Close modal"
                            >
                                <Icon name="CloseIcon" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </FocusTrap>
    )

    return ReactDOM.createPortal(modal, document.getElementById("base-modal"))
}

ModalWrapper.propTypes = {
    closeModal: PropTypes.func,
    children: PropTypes.any,
    variant: PropTypes.string,
    closeOnBodyclick: PropTypes.bool,
}

export default ModalWrapper
